<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary bg-soft">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Chào mừng bạn !</h5>
                    <p>Vui lòng đăng nhập để tiếp tục.</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img src="/stoke/assets/images/profile-img.png" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="auth-logo">
                <a href="index.html" class="auth-logo-light">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img
                        src="/stoke/assets/images/logo-light.svg"
                        alt=""
                        class="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </a>

                <a href="index.html" class="auth-logo-dark">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img
                        src="/stoke/assets/images/logo.svg"
                        alt=""
                        class="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </a>
              </div>
              <div class="p-2">
                <form @submit.prevent="login()" class="form-horizontal">
                  <div class="mb-3">
                    <label for="username" class="form-label">Username</label>
                    <input
                      v-model="username"
                      type="text"
                      class="form-control"
                      id="username"
                      placeholder="Enter username"
                    />
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Mật khẩu</label>
                    <div class="input-group auth-pass-inputgroup">
                      <input
                        v-model="password"
                        type="password"
                        class="form-control"
                        placeholder="Enter password"
                        aria-label="Password"
                        aria-describedby="password-addon"
                      />
                      <button class="btn btn-light" type="button" id="password-addon">
                        <i class="mdi mdi-eye-outline"></i>
                      </button>
                    </div>
                  </div>

                  <div class="mt-3 d-grid">
                    <button class="btn btn-primary waves-effect waves-light" type="submit">
                      Đăng Nhập
                    </button>
                  </div>

                  <div class="mt-4 text-center">
                    <h5 class="font-size-14 mb-3">Hoặc đăng nhập với</h5>

                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          href="javascript::void()"
                          class="social-list-item bg-primary text-white border-primary"
                        >
                          <i class="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript::void()"
                          class="social-list-item bg-info text-white border-info"
                        >
                          <i class="mdi mdi-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript::void()"
                          class="social-list-item bg-danger text-white border-danger"
                        >
                          <i class="mdi mdi-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import authApi from "@/api/auth.js";

export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async login() {
      const { username, password } = this;
      let data = await authApi.login({
        username,
        password,
      });
      if (data?.success) {
        if (data?.data?.role === "admin") {
          this.$swal("Thành công", "Đăng nhập thành công !", "success");
          this.$store.dispatch(types.LOGIN, data);
        } else {
          this.$swal("Lỗi", "Bạn chưa được cấp quyền truy cập Admin !", "error");
        }
      } else {
        this.$swal("Lỗi", data?.message || "Đăng nhập thất bại !", "error");
      }
    },
  },
};
</script>
